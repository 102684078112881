import { Card, Col, Layout, Row, Space, Statistic, Table, DatePicker, Select, Spin, Button, message } from "antd";
import { Header } from "antd/es/layout/layout";
import Logo from "./logo";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "./axiosConfig";
import { AuthContext } from "../AuthContext";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const InternalDash = () => {
    const { user } = useContext(AuthContext);
    const [mobilePickups, setMobilePickups] = useState([]);
    const [leadsGenerated, setLeadsGenerated] = useState([]);
    const [leadCount, setLeadCount] = useState(0);
    const [mobilePickupCount, setMobilePickupCount] = useState(0);
    const [dateRange, setDateRange] = useState([dayjs().subtract(30, 'day'), dayjs()]);
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [lists, setLists] = useState([]);
    const [listId, setListId] = useState(null);
    const [loadingClients, setLoadingClients] = useState(true);
    const [loadingLists, setLoadingLists] = useState(false);
    const [loadingDashboard, setLoadingDashboard] = useState(false);

    useEffect(() => {
        // Fetch clients
        setLoadingClients(true);
        axiosInstance.get("https://pbj.digital/clients").then((response) => {
            setClients(response.data);
            setLoadingClients(false);
            if (user.companyName) {
                const client = response.data.find(client => client.companyName === user.companyName);
                if (client) {
                    setClientId(client.id);
                }
            }
        }).catch(() => {
            setLoadingClients(false);
        });
    }, [user.companyName]);

    useEffect(() => {
        if (clientId) {
            // Fetch lists for the selected client
            setLoadingLists(true);
            axiosInstance.get("https://pbj.digital/get-lists").then((response) => {
                const matchedLists = response.data.filter(list => list.clientId === clientId);
                setLists(matchedLists);
                setLoadingLists(false);
            }).catch(() => {
                setLoadingLists(false);
            });
        } else {
            setLists([]);
            setListId(null);
        }
    }, [clientId]);

    useEffect(() => {
        if (clientId && listId) {
            setLoadingDashboard(true);
            axiosInstance.get(`/dashboard?clientId=${user.companyId}`).then((response) => {
                const filteredData = response.data.filter(item => {
                    const itemDate = dayjs(item.created_at);
                    return item.matchBoxId === listId &&
                        ['MEETING BOOKED', 'SEND INFORMATION', 'NOT ME', 'NOT INTERESTED', 'FOLLOW UP', 'RETIRED', 'LEFT COMPANY', 'WRONG NUMBER', 'DO NOT CALL', 'HUNG UP'].includes(item.disposition.toUpperCase()) &&
                        itemDate.isAfter(dateRange[0].startOf('day')) && itemDate.isBefore(dateRange[1].endOf('day'));
                });
                setMobilePickups(filteredData.reverse());
                console.log(mobilePickups)
                setMobilePickupCount(filteredData.length);

                const filteredData2 = response.data.filter(item => {
                    const itemDate = dayjs(item.created_at);
                    return item.matchBoxId === listId &&
                        ['Meeting Booked', 'Send Information', 'Follow Up'].includes(item.disposition) &&
                        itemDate.isAfter(dateRange[0].startOf('day')) && itemDate.isBefore(dateRange[1].endOf('day'));
                });
                setLeadsGenerated(filteredData2.reverse());
                setLeadCount(filteredData2.length);
                setLoadingDashboard(false);
            }).catch(() => {
                setLoadingDashboard(false);
            });
        } else if (!listId) {
            setMobilePickups([]);
            setMobilePickupCount(0);
            setLeadsGenerated([]);
            setLeadCount(0);
        }
    }, [clientId, listId, dateRange]);

    const columns = [
        { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
        { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name' },
        { title: 'Title', dataIndex: 'title', key: 'title' },
        { title: 'Phone', dataIndex: 'phone_number', key: 'phone_number' },
        { title: 'Disposition', dataIndex: 'disposition', key: 'disposition' },
        {
            title: 'Listen',
            key: 'recording_url',
            fixed: 'right',
            render: (record) => <a href={record.recording_url} target="_blank" rel="noreferrer">Listen</a>
        }
    ];

    const handleCopyLink = () => {
        if (clientId) {
            const encryptedClientId = CryptoJS.AES.encrypt(clientId.toString(), 'secret-key').toString();
            const link = `https://report.pbj.digital?clientId=${encodeURIComponent(encryptedClientId)}`;
            navigator.clipboard.writeText(link).then(() => {
                message.success('Link copied to clipboard!');
            }).catch(() => {
                message.error('Failed to copy link to clipboard.');
            });
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ background: 'none', padding: '0 16px' }}>
                <Space direction="vertical">
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Logo />
                        </Col>
                        <Col span={8}>
                            {loadingClients ? (
                                <Spin />
                            ) : (
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select Client"
                                    value={clientId}
                                    onChange={(value) => setClientId(value)}
                                >
                                    {clients.map(client => (
                                        <Option key={client.id} value={client.id}>{client.companyName}</Option>
                                    ))}
                                </Select>
                            )}
                        </Col>
                        <Col span={8}>
                            {loadingLists ? (
                                <Spin />
                            ) : (
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select List"
                                    value={listId}
                                    onChange={(value) => setListId(value)}
                                    disabled={!clientId}
                                >
                                    {lists.map(list => (
                                        <Option key={list.ListID} value={list.ListID}>{list.name}</Option>
                                    ))}
                                </Select>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <RangePicker
                                defaultValue={[dayjs().subtract(30, 'day'), dayjs()]}
                                onChange={(dates) => setDateRange(dates)}
                                allowClear
                            />
                        </Col>
                        <Col span={12}>
                            {clientId && (
                                <Button type="primary" style={{ marginTop: 20 }} onClick={handleCopyLink}>
                                    Copy Report Link to Clipboard
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Statistic title="Mobile Pickups" value={mobilePickupCount} />
                            <Statistic title="Leads Generated" value={leadCount} />
                        </Col>
                        <Col span={12}>
                            {loadingDashboard ? <Spin /> : (
                                <Table dataSource={mobilePickups} columns={columns} rowKey="id" style={{ marginTop: 20 }} />
                            )}
                        </Col>
                    </Row>
                </Space>
            </Header>
        </Layout>
    );
};