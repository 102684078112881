// App.js
import React, { useEffect, useRef, useState, useContext } from 'react';
import {
  Layout,
  Menu,
  Button,
  Modal,
  Form,
  Input,
  Typography,
  Space,
  Upload,
  message,
  Statistic,
  ConfigProvider,
  Switch,
  Table,
  Tag,
  theme as antdTheme,
  Row,
  Col,
  Select,
  Watermark,
  Tabs,
  Card,
  Spin,
} from 'antd';
import { UploadOutlined, FireOutlined, PlusOutlined, RedoOutlined, CloseOutlined, DatabaseOutlined, PhoneOutlined, ExperimentOutlined, SettingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './App.css';
import PlivoWebphone, { pushNumbersToEndpoint } from './components/dialer';
import CallTime from './components/CallTime';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import Logo from './components/logo';
import CsvUploadModal from './components/CSVMapper';
import CsvUploadModalComponent from './components/CSVMapper';
import Footer from './components/Footer';
import axiosInstance from './components/axiosConfig';
import { AuthContext } from './AuthContext';
import DataGatherer from './components/DataGatherer';
import NotebookTextEditor from './components/NotebookTextEditor';
import TabPane from 'antd/es/tabs/TabPane';
import useToken from 'antd/es/theme/useToken';
import CallDataTable from './components/CallDataTable';
import CallHistorySearch from './components/CallHistorySearch';
import AssignMatchboxModal from './components/AssignMatchboxModal';
import AssignListToClientModal from './components/AssignListToClient';
import { Option } from 'antd/es/mentions';
import { updateDisposition } from './components/updateContactStats';
import CreateNewClient from './components/CreateNewClient';
import ClientManagement from './components/ClientManagement';
import { title } from 'process';
import { render } from '@testing-library/react';
import { ClientDash } from './components/ClientDashViewer';
import { InternalDash } from './components/InternalReport';

const authToken = localStorage.getItem('authToken');

const { Header, Sider, Content } = Layout;
const { Text, Title } = Typography;
const ResponsiveGridLayout = WidthProvider(Responsive);

const isObject = (obj) => obj && typeof obj === 'object' && !Array.isArray(obj);


export const convertToProperJSON = (input) => {
  if (!input) {
    // Input is null, undefined, or empty
    return {};
  }

  let data = {};

  try {
    if (typeof input === 'string') {
      // First, parse the outer JSON string
      data = JSON.parse(input);
    } else if (typeof input === 'object') {
      // Input is already an object
      data = input;
    } else {
      // Input is neither a string nor an object
      return {};
    }
  } catch (e) {
    console.error('Failed to parse input JSON:', e);
    return {};
  }

  // Function to clean and parse nested JSON-like strings
  const parseNestedJSON = (str) => {
    if (str === null || str === 'null' || str === undefined) {
      return {};
    }
    try {
      // Replace single quotes with double quotes
      let correctedStr = str.replace(/'/g, '"');
      // Replace '"null"' with null
      correctedStr = correctedStr.replace(/"null"/g, 'null');
      return JSON.parse(correctedStr);
    } catch (e) {
      console.error('Failed to parse nested JSON:', e, 'Input:', str);
      return {};
    }
  };

  // Parse 'meetingInfo' if it's a string
  if (typeof data.meetingInfo === 'string') {
    data.meetingInfo = parseNestedJSON(data.meetingInfo);
  }

  // Parse 'followUpInfo' if it's a string
  if (typeof data.followUpInfo === 'string') {
    data.followUpInfo = parseNestedJSON(data.followUpInfo);
  }

  // Convert string booleans to actual booleans
  if (typeof data.meetingSet === 'string') {
    data.meetingSet = data.meetingSet.toLowerCase() === 'true';
  }

  if (typeof data.followUpCallSet === 'string') {
    data.followUpCallSet = data.followUpCallSet.toLowerCase() === 'true';
  }

  return data;
}


// Helper function to find differences between two objects or arrays
const findDifferences = (obj1, obj2, path = '') => {
  let diffs = [];

  // Handle arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    const maxLength = Math.max(obj1.length, obj2.length);
    for (let i = 0; i < maxLength; i++) {
      const newPath = `${path}[${i}]`;
      const val1 = obj1[i];
      const val2 = obj2[i];

      if (isObject(val1) && isObject(val2)) {
        diffs = diffs.concat(findDifferences(val1, val2, newPath));
      } else if (Array.isArray(val1) && Array.isArray(val2)) {
        diffs = diffs.concat(findDifferences(val1, val2, newPath));
      } else if (val1 !== val2) {
        diffs.push({
          path: newPath,
          value1: val1,
          value2: val2,
        });
      }
    }
  }
  // Handle objects
  else if (isObject(obj1) && isObject(obj2)) {
    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    keys.forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (isObject(val1) && isObject(val2)) {
        diffs = diffs.concat(findDifferences(val1, val2, newPath));
      } else if (Array.isArray(val1) && Array.isArray(val2)) {
        diffs = diffs.concat(findDifferences(val1, val2, newPath));
      } else if (val1 !== val2) {
        diffs.push({
          path: newPath,
          value1: val1,
          value2: val2,
        });
      }
    });
  }
  // Handle primitive types
  else if (obj1 !== obj2) {
    diffs.push({
      path: path || 'root',
      value1: obj1,
      value2: obj2,
    });
  }

  return diffs;
};


export const filterList = (list) => {
  const today = new Date();
  const todayStr = today.toISOString().split('T')[0]; // Format: 'YYYY-MM-DD'

  // Filter items based on the rules
  const filteredItems = list.items.filter(item => {
    // Rule 1: Exclude if total_calls is 10 or more
    const totalCalls = item.total_calls ? parseInt(item.total_calls, 10) : 0;
    if (totalCalls >= 10) return false;

    // Rule 2: Exclude if last_called is today
    if (item.last_called) {
      const lastCalledDate = new Date(item.last_called);
      const lastCalledStr = lastCalledDate.toISOString().split('T')[0];
      if (lastCalledStr === todayStr) return false;
    }

    // Rule 3: Exclude if disposition is not 'no answer'
    if (item.disposition.toLowerCase() !== 'no answer') return false;

    return true;
  }).slice(0, 3); // Limit to 3 items

  // Return the filtered list in the same format
  return {
    ListID: list.ListID,
    ListName: list.ListName,
    items: filteredItems
  };
};


function App() {
  // Define initial layouts
  const initialLayouts = { "lg": [{ "w": 6, "h": 10, "x": 0, "y": 0, "i": "dialer-1728327620059", "moved": false, "static": false }, { "w": 6, "h": 23, "x": 6, "y": 0, "i": "callerInfo-1728327635788", "moved": false, "static": false }, { "w": 6, "h": 13, "x": 0, "y": 10, "i": "contactsBeingCalled-1728327649957", "moved": false, "static": false }], "xs": [{ "w": 1, "h": 10, "x": 0, "y": 0, "i": "dialer-1728327620059", "moved": false, "static": false }, { "w": 1, "h": 22, "x": 1, "y": 0, "i": "callerInfo-1728327635788", "moved": false, "static": false }, { "w": 1, "h": 12, "x": 0, "y": 10, "i": "contactsBeingCalled-1728327649957", "moved": false, "static": false }], "md": [{ "w": 5, "h": 10, "x": 0, "y": 0, "i": "dialer-1728327620059", "moved": false, "static": false }, { "w": 5, "h": 22, "x": 5, "y": 0, "i": "callerInfo-1728327635788", "moved": false, "static": false }, { "w": 5, "h": 12, "x": 0, "y": 10, "i": "contactsBeingCalled-1728327649957", "moved": false, "static": false }], "sm": [{ "w": 2, "h": 10, "x": 0, "y": 0, "i": "dialer-1728327620059", "moved": false, "static": false }, { "w": 4, "h": 22, "x": 2, "y": 0, "i": "callerInfo-1728327635788", "moved": false, "static": false }, { "w": 2, "h": 12, "x": 0, "y": 10, "i": "contactsBeingCalled-1728327649957", "moved": false, "static": false }] };

  // Load layouts from localStorage or use initialLayouts
  const { user } = useContext(AuthContext);
  
  const [layouts, setLayouts] = useState(() => {
    const savedLayouts = localStorage.getItem('layouts');
    return savedLayouts ? JSON.parse(savedLayouts) : initialLayouts;
  });

  const [collapsed, setCollapsed] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRowKeyAll, setSelectedRowKeyAll] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowAll, setSelectedRowAll] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isAddWebsiteModalVisible, setIsAddWebsiteModalVisible] = useState(false);
  const [lists, setLists] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [dataView, setDataView] = useState([]);
  const [form] = Form.useForm();
  const [uploadForm] = Form.useForm();
  const [addWebsiteForm] = Form.useForm();
  const [transcript, setTranscript] = useState('');
  const [summary, setSummary] = useState('');
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [audioUrlHold, setAudioUrlHold] = useState('');
  const [callInfo, setCallInfo] = useState();
  const [selectedMatchbox, setSelectedMatchbox] = useState(null);
  const [selectedListId, setSelectedListId] = useState(null); // Track selected list
  const hangupButtonRef = useRef(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [unfilteredMatchbox, setUnfilteredMatchbox] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAddComponentModalVisible, setIsAddComponentModalVisible] = useState(false);
  const [addComponentForm] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [isCompact, setIsCompact] = useState(false);
  const [selectedTab, setSelectedTab] = useState('1');
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedManageMatchbox, setSelectedManageMatchbox] = useState(null);
  const [selectedManageRowKey, setSelectedManageRowKey] = useState(null);
  const selectedListIdRef = useRef(selectedListId);
  const [reportData, setReportData] = useState([]);
  const [dispo, setDispo] = useState('');

  useEffect(() => {
    const fetchReportData = async () => {
      setLoading(true);
      try {
        const clientId = user.companyId; // Assuming `user.companyId` holds the clientId value
        const response = await axiosInstance.get('/dashboard', {
          params: { clientId }, // Pass `clientId` as a query parameter
        });
        setReportData(response.data); // Assuming response.data is the array of contacts
      } catch (error) {
        console.error('Failed to fetch report data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchReportData();
  }, [user.companyId]);

  const reportColumns = [
    {
      title: 'First Name',
      dataIndex: 'first_name', // Replace with the actual key from your API response
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name', // Replace with the actual key from your API response
      key: 'last_name',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number', // Replace with the actual key from your API response
      key: 'phone_number',
    },
    {
      title: 'Email',
      dataIndex: 'email', // Replace with the actual key from your API response
      key: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company_name', // Replace with the actual key from your API response
      key: 'company_name',
    },
    {
      title: 'Title',
      dataIndex: 'title', // Replace with the actual key from your API response
      key: 'title',
    },
    {
      title: 'notes',
      dataIndex: 'notes', // Replace with the actual key from your API response
      key: 'notes',
      render: (notes) => (
        <div style={{whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: notes }} />
      )
    },
    {
      title: 'Disposition',
      dataIndex: 'disposition', // Replace with the actual key from your API response
      key: 'disposition',
    },
    {
      title: 'Listen Link',
      dataIndex: 'recording_url', // Replace with the actual key from your API response
      key: 'recording_url',
      render: (recording_url) => (
        <a href={recording_url} target="_blank" rel="noreferrer">Listen</a>
      )
    }
  ];

  const handleDispositionChange = (value, record) => {
    updateDisposition(record.id, value);
  };
  const handleDispositionChangeMainPage = (value, id) => {
    setDispo(value);
    updateDisposition(id, value);
  };

  function MatchboxAssignment() {
    const [matchboxes, setMatchboxes] = useState([]);
    const [callers, setCallers] = useState([]);
    const [clientIds, setClientIds] = useState([]);
    const [selectedMatchbox, setSelectedMatchbox] = useState(null);
    const [selectedCaller, setSelectedCaller] = useState(null);
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
    const [isAssignClientModalVisible, setIsAssignClientModalVisible] = useState(false);
    const [form] = Form.useForm();

    // Fetch matchboxes and callers when the component mounts
    useEffect(() => {
      fetchMatchboxes();
      fetchCallers();
      fetchClientIds();
    }, [authToken, shouldRefetch, lists]);

    const fetchMatchboxes = async () => {
      try {
        const response = await axiosInstance.get('/get-lists');
        setMatchboxes(response.data);
      } catch (error) {
        console.error('Error fetching matchboxes:', error);
      }
    };

    const fetchCallers = async () => {
      try {
        const response = await axiosInstance.get('/get-all-callers');
        let callerHolder = response.data;
        callerHolder.unshift({ id: 0, username: 'Unassigned', role: 'Unassigned' });
        setCallers(callerHolder);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching callers:', error);
      }
    };

    const fetchClientIds = async () => {
      try { 
        const response = await axiosInstance.get('/clients');
        setClientIds(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    const showAssignModal = () => {
      setIsAssignModalVisible(true);
    };
    const showAssignClientModal = () => {
      setIsAssignClientModalVisible(true);
    };

    const handleAssignOk = () => {
      form.validateFields().then((values) => {
        assignMatchbox(values);
        form.resetFields();
        setIsAssignModalVisible(false);
      });
    };

    const handleAssignClientOk = () => {
      form.validateFields().then((values) => {
        assignMatchbox(values);
        form.resetFields();
        setIsAssignClientModalVisible(false);
      });
    };

    const handleAssignCancel = () => {
      setIsAssignModalVisible(false);
    };

    const handleAssignCleintCancel = () => {
      setIsAssignClientModalVisible(false);
    };

    const assignMatchbox = async (values) => {
      try {
        await axiosInstance.post('/assign-matchbox', values, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        message.success('Matchbox assigned successfully.');
        fetchMatchboxes(); // Refresh the list
      } catch (error) {
        console.error('Error assigning matchbox:', error);
        message.error(error.response?.data?.error || 'Error assigning matchbox.');
      }
    };

    // Updated columns to handle multiple assignments
    const columns = [
      { title: 'Matchbox ID', dataIndex: 'ListID', key: 'ListID' },
      { title: 'Matchbox Name', dataIndex: 'ListName', key: 'ListName' },
      {
        title: 'Assigned To',
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        render: (assignedTo) => {
          if (!assignedTo) {
            return <span>Unassigned</span>;
          }

          // Split the comma-separated string into an array of IDs
          const assignedIds = assignedTo.split(',').map(id => id.trim());

          // Map the IDs to caller objects
          const assignedCallers = assignedIds
            .map(id => callers.find(caller => caller.id.toString() === id))
            .filter(caller => caller !== undefined); // Filter out any undefined callers

          if (assignedCallers.length === 0) {
            return <span>Unassigned</span>;
          }

          // Render each assigned caller's username as a Tag
          return (
            <>
              {assignedCallers.map(caller => (
                <Tag color="blue" key={caller.id}>
                  {caller.username}
                </Tag>
              ))}
            </>
          );
        },
      },
    ];

    // Updated columnsCompact to handle multiple assignments
    const columnsCompact = [
      { title: 'Matchbox Name', dataIndex: 'ListName', key: 'ListName' },
      {
        title: 'Assigned To',
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        render: (assignedTo) => {
          if (!assignedTo) {
            return <span>Unassigned</span>;
          }

          // Split the comma-separated string into an array of IDs
          const assignedIds = assignedTo.split(',').map(id => id.trim());

          // Map the IDs to caller objects
          const assignedCallers = assignedIds
            .map(id => callers.find(caller => caller.id.toString() === id))
            .filter(caller => caller !== undefined); // Filter out any undefined callers

          if (assignedCallers.length === 0) {
            return <span>Unassigned</span>;
          }

          // Render each assigned caller's username as a Tag
          return (
            <>
              {assignedCallers.map(caller => (
                <Tag color="blue" key={caller.id}>
                  {caller.username}
                </Tag>
              ))}
            </>
          );
        },
      },
    ];

    const columnsCompactItems =
      [{
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name',
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name',
      },
      {
        title: 'Phone',
        dataIndex: 'kixie_phone_number',
        key: 'kixie_phone_number',
      },
      {
        title: 'Disposition',
        dataIndex: 'disposition',
        key: 'disposition',
      }];
    const columnsItems = [
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name',
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name',
      },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      {
        title: 'Phone',
        dataIndex: 'kixie_phone_number',
        key: 'kixie_phone_number',
      },
      {
        title: 'Contact URL',
        dataIndex: 'contact_url',
        key: 'contact_url',
      },
      {
        title: 'Disposition',
        dataIndex: 'disposition',
        key: 'disposition',
        render: (disposition, record) => (
          <Select
            defaultValue={disposition}
            onChange={(value) => handleDispositionChange(value, record)}
          >
            <Option value={disposition}>{disposition}</Option>
            <Option value="Not interested">Not interested</Option>
            <Option value="Bad Data">Bad Data</Option>
            <Option value="Left Company">Left Company</Option>
            <Option value="Follow Up">Follow Up</Option>
            <Option value="Hung Up">Hung Up</Option>
            <Option value="Not Me">Not Me</Option>
            <Option value="Retired">Retired</Option>
            <Option value="Do Not Call">Do Not Call</Option>
            <Option value="Meeting Booked">Meeting Booked</Option>
            <Option value="Call Back Later">Call Back Later</Option>
            <Option value="no answer">No Answer</Option>
          </Select>
        ),
      },
    ];


    return (
      <>
        <Button type="primary" onClick={showAssignModal}>
          Assign Matchbox to Caller
        </Button>
        <Button type="primary" onClick={showAssignClientModal}>
          Assign Matchbox to Client
        </Button>
        <Table
          columns={isCompact ? columnsCompact : columns}
          dataSource={matchboxes}
          rowKey="ListID"
          pagination={{ pageSize: 50 }}
          size='small'
          onRow={(record) => ({
            onClick: () => {
              onRowManageClick(record);
              console.log(record)
            },
          })}
          rowClassName={rowManageClassName}
        />
        <Table
          columns={isCompact ? columnsCompactItems : columnsItems}
          dataSource={selectedManageMatchbox?.items}
          rowKey="ListID"
          pagination={{ pageSize: 50 }}
          size='small'
        // onRow={(record) => ({
        //   onClick: () => {
        //     onRowManageClick(record);
        //     console.log(record)
        //   },
        // })}
        // rowClassName={rowManageClassName}
        />

        <AssignMatchboxModal
          isAssignModalVisible={isAssignModalVisible}
          setIsAssignModalVisible={setIsAssignModalVisible}
          fetchMatchboxes={fetchMatchboxes}
          matchboxes={matchboxes}
          callers={callers}
          authToken={authToken}
        />
        <AssignListToClientModal
          isAssignListModalVisible={isAssignClientModalVisible}
          setIsAssignListModalVisible={setIsAssignClientModalVisible} 
          fetchMatchboxes={fetchMatchboxes}
          matchboxes={matchboxes}
          clientIds={clientIds}
          authToken={authToken}
          />
      </>
    );
  }

  // Load dark mode preference from localStorage
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  // useEffect to handle refetching based on the signal
  useEffect(() => {
    console.log('Should refetch:', shouldRefetch);
    setDispo('');
    if (shouldRefetch) {
      console.log('Re-fetching the selected list...');
      setShouldRefetch(false);
      // Simulate refetching and reset the signal after completion
      setTimeout(() => {
        axiosInstance.get('/get-lists')
          .then((response) => {
            let fetchedLists = response.data;
            console.log('Fetched Lists:', fetchedLists);

            // Updated filter logic to handle multiple assignments
            fetchedLists = fetchedLists.filter((list) => {
              if (!list.assignedTo) return false; // Exclude if `assignedTo` is empty or null

              // Split the `assignedTo` string into an array of trimmed IDs
              const assignedIds = list.assignedTo.split(',').map(id => id.trim());

              // Check if the current user's ID is in the array
              return assignedIds.includes(user.id.toString());
            });
            setLists(fetchedLists);
            console.log('Filtered Lists:', fetchedLists);
            console.log('Selected List ID:', selectedListId);
            // if (fetchedLists.length > 0 && !selectedListId) {
              //   // Set initial selected list ID to the first one if none is selected
              //   setSelectedListId(fetchedLists[0].ListID);
              // }
              
              // Find the list that matches the selectedListId
              const selectedList = fetchedLists.find(list => list.ListID === selectedListId);
              
            
            if (selectedList) {
              const numbers = selectedList.items || []; // Ensure `items` exists

              setUnfilteredMatchbox(selectedList);

              const filteredMatchbox = filterList(selectedList);
              setSelectedMatchbox(filteredMatchbox);
              localStorage.setItem('selectedMatchbox', JSON.stringify(filteredMatchbox));

              // Extract the first three phone numbers to be pushed
              const numsToBePushed = numbers.slice(0, 3).map(contact => contact.kixie_phone_number);
              pushNumbersToEndpoint(numsToBePushed);

              console.log('Selected List:', selectedList);
            } else {
              console.warn('List with the selectedListId not found.');
            }
          })
          .catch((error) => {
            console.error('Error fetching lists:', error);
            message.error('Failed to fetch matchboxes. Please try again later.');
          });


      }, 1000); // Simulating a 1-second fetch delay
    }
  }, [shouldRefetch, selectedListId, user.id, lists]);

  // Save dark mode preference to localStorage
  useEffect(() => {
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);


  const componentOptions = [
    { label: 'Dialer', value: 'dialer', roles: ['superAdmin', 'admin', 'caller'] },
    { label: 'My Call History List', value: 'callList', roles: ['superAdmin', 'admin', 'caller'] },
    { label: 'All Call History List', value: 'allCallList', roles: ['superAdmin', 'admin', 'dataManager'] },
    { label: 'Call Time', value: 'callTime', roles: ['superAdmin', 'admin', 'caller'] },
    { label: 'Summary', value: 'summary', roles: ['superAdmin', 'admin', 'caller'] },
    { label: 'Prospect Info', value: 'callerInfo', roles: ['superAdmin', 'admin', 'caller'] },
    { label: 'MatchBoxes', value: 'matchBoxes', roles: ['superAdmin', 'admin', 'caller'] }, // Only admin
    { label: 'Contacts Being Called', value: 'contactsBeingCalled', roles: ['superAdmin', 'admin', 'caller'] },
    { label: 'Matchbox Assignment', value: 'matchboxAssignment', roles: ['superAdmin', 'admin', 'dataManager'] },
    { label: 'Call History', value: 'callHistory', roles: ['superAdmin', 'admin', 'dataManager'] },
    { label: 'Data Gatherer', value: 'dataGatherer', roles: ['admin', 'superAdmin', 'dataManager'] }

    // Add more components as needed
  ];
  // Fetch the list of lists on mount
  useEffect(() => {
    console.log('Fetching lists...');
    const fetchLists = async () => {
      try {
        const res = await axiosInstance.get('/get-lists');
        let fetchedLists = res.data;

        // Filter lists where `assignedTo` includes the current user's ID
        fetchedLists = fetchedLists.filter((list) => {
          if (!list.assignedTo) return false; // Exclude if `assignedTo` is empty or null

          // Split the `assignedTo` string into an array and trim whitespace
          const assignedIds = list.assignedTo.split(',').map(id => id.trim());

          // Check if the current user's ID is in the array
          return assignedIds.includes(user.id.toString());
        });

        setLists(fetchedLists);


      } catch (error) {
        console.error('Error fetching lists:', error);
        message.error('Failed to fetch matchboxes. Please try again later.');
      }
    };

    fetchLists();
  }, [shouldRefetch]);


  // Keep the ref updated with the latest selectedListId
  useEffect(() => {
    selectedListIdRef.current = selectedListId;
  }, [selectedListId]);

  useEffect(() => {
    console.log("--------------------------------------------------------------")
    // Observe the answeredContactName element for changes
    console.log('Observing answeredContactName element...');
    const answeredContactName = document.getElementById('answeredContactName');

    if (!answeredContactName) return;

    const observer = new MutationObserver((mutationsList) => {
      console.log('Mutation observed:', mutationsList);
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // innerHTML has changed
          axiosInstance.get('/get-lists')
            .then((response) => {
              let fetchedLists = response.data;

              // Filter lists assigned to the current user
              fetchedLists = fetchedLists.filter((list) => {
                if (!list.assignedTo) return false;
                const assignedIds = list.assignedTo.split(',').map(id => id.trim());
                return assignedIds.includes(user.id.toString());
              });
              console.log('Fetched Lists:', fetchedLists);
              setLists(fetchedLists);

              // Find the selected list
              const selectedList = fetchedLists.find(
                list => list.ListID === selectedListIdRef.current
              );

              if (selectedList) {
                const numbers = selectedList.items || [];

                setUnfilteredMatchbox(selectedList);

                const filteredMatchbox = filterList(selectedList);
                setSelectedMatchbox(filteredMatchbox);
                localStorage.setItem('selectedMatchbox', JSON.stringify(filteredMatchbox));

                // Push the first three phone numbers
                const numsToBePushed = numbers
                  .slice(0, 3)
                  .map(contact => contact.kixie_phone_number);
                pushNumbersToEndpoint(numsToBePushed);
              } else {
                console.warn('List with the selectedListId not found.');
              }
            })
            .catch((error) => {
              console.error('Error fetching lists:', error);
              message.error('Failed to fetch matchboxes. Please try again later.');
            });
        }
      }
    });

    observer.observe(answeredContactName, {
      childList: true,
      subtree: true,
    });
    console.log("--------------------------------------------------------------")

  }, []);

  // Handler functions for table operations
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => setFilteredInfo({});
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  // Modal handling functions
  const showModal = () => setIsModalVisible(true);
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        addAssociation(values);
        setIsModalVisible(false);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (unfilteredMatchbox) {
      const filteredMatchbox = filterList(unfilteredMatchbox);

      // Update the button text based on deep comparison
      const differences = findDifferences(unfilteredMatchbox, filteredMatchbox);
      const buttonText = differences.length === 0 ? 'START DAILING' : 'DIAL NEXT';
      const mainCallButton = document.getElementById('mainCallButton');
      if (mainCallButton) {
        mainCallButton.innerHTML = buttonText;
      }

      // Log the matchboxes
      console.log('Selected Matchbox:', unfilteredMatchbox);
      console.log('Filtered Matchbox:', filteredMatchbox);

      // Log the differences
      if (differences.length > 0) {
        console.log('Differences found:', differences);
      } else {
        console.log('No differences found between selected and filtered matchboxes.');
      }
    }
  }, [selectedMatchbox, filterList]);


  const handleCancel = () => setIsModalVisible(false);

  // Function to add a new association
  const addAssociation = (values) => {
    fetch('https://gomatchstick.co/api/add-association', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then(console.log)
      .catch(console.error);
  };

  // Interval fetching for the latest data
  useEffect(() => {
    const interval = setInterval(() => {
      fetch('https://gomatchstick.co/api/latest-data')
        .then((res) => res.json())
        .then((data) => setDataView(data))
        .catch(console.error);
    }, 600000);

    return () => clearInterval(interval);

  }, []);


  const navigateToUrl = (url) => window.open(url, '_blank');



  const columns = [
    {
      title: 'First Name',
      dataIndex: 'fname',
      key: 'fname',
      filteredValue: filteredInfo.fname || null,
      onFilter: (value, record) => record.fname.includes(value),
      sorter: (a, b) => a.fname.length - b.fname.length,
      sortOrder: sortedInfo.columnKey === 'fname' ? sortedInfo.order : null,
      ellipsis: true,
    },
    { title: 'Last Name', dataIndex: 'lname', key: 'lname' },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => <Tag color={record?.company_color}>{text}</Tag>,
    },
    { title: 'MatchBoxID', dataIndex: 'matchBoxId', key: 'matchBoxId' },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
      sorter: (a, b) => a.contactName.localeCompare(b.contactName),
      sortOrder: sortedInfo.columnKey === 'contactName' ? sortedInfo.order : null,
    },
    {
      title: 'List Name',
      dataIndex: 'listName',
      key: 'listName',
      sorter: (a, b) => a.listName.localeCompare(b.listName),
      sortOrder: sortedInfo.columnKey === 'listName' ? sortedInfo.order : null,
    },
  ];

  const columnsCompact = [
    {
      title: 'First Name',
      dataIndex: 'fname',
      key: 'fname',
      filteredValue: filteredInfo.fname || null,
      onFilter: (value, record) => record.fname.includes(value),
      sorter: (a, b) => a.fname.length - b.fname.length,
      sortOrder: sortedInfo.columnKey === 'fname' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'List Name',
      dataIndex: 'listName',
      key: 'listName',
      sorter: (a, b) => a.listName.localeCompare(b.listName),
      sortOrder: sortedInfo.columnKey === 'listName' ? sortedInfo.order : null,
    },
  ];

  const myColumns = [
    {
      title: 'First Name',
      dataIndex: 'fname',
      key: 'fname'
    },
    { title: 'Last Name', dataIndex: 'lname', key: 'lname' },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => <Tag color={record?.company_color}>{text}</Tag>,
    },
    { title: 'MatchBoxID', dataIndex: 'matchBoxId', key: 'matchBoxId' },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
      sorter: (a, b) => a.contactName.localeCompare(b.contactName),
      sortOrder: sortedInfo.columnKey === 'contactName' ? sortedInfo.order : null,
    },
    {
      title: 'List Name',
      dataIndex: 'listName',
      key: 'listName',
      sorter: (a, b) => a.listName.localeCompare(b.listName),
      sortOrder: sortedInfo.columnKey === 'listName' ? sortedInfo.order : null,
    },
  ];


  const myColumnsCompact = [
    {
      title: 'First Name',
      dataIndex: 'fname',
      key: 'fname'
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
      sorter: (a, b) => a.contactName.localeCompare(b.contactName),
      sortOrder: sortedInfo.columnKey === 'contactName' ? sortedInfo.order : null,
    },
    {
      title: 'List Name',
      dataIndex: 'listName',
      key: 'listName',
      sorter: (a, b) => a.listName.localeCompare(b.listName),
      sortOrder: sortedInfo.columnKey === 'listName' ? sortedInfo.order : null,
    },
  ];

  const tools = [
    {
      type: "function",
      function: {
        name: "informationPuller",
        description:
          "Use this to format pulled information from the transcript. must be valid JSON",
        parameters: {
          type: "object",
          properties: {
            meetingSet: {
              type: "string",
              description: "respond 'true' or 'false'",
            },
            meetingInfo: {
              type: "string",
              description: "respond with {'time': (dayjs format date and time or null), 'people': (list first and last name separated by comma or null if none), 'meetingType': (Google, Teams, In person, etc or null)}",
            },
            calleeEmail: {
              type: "string",
              description: "respond with email formatted or null",
            },
            calleeName: {
              type: "string",
              description: "respond with first and last name of the person that answers or null",
            },
            followUpCallSet: {
              type: "string",
              description: "respond 'true' or 'false'",
            },
            followUpInfo: {
              type: "string",
              description: "respond with {'time': (dayjs format date and time or null), 'person': (list first and last name separated by comma or null if none), 'number': (format +123456790 or +12345678901 or null)}",
            },
            disposition: {
              type: "string",
              description: "out of these options, pick the best match: 'Not interested', 'Send Information', 'Bad Data', 'Left Company', 'Follow Up', 'Hung Up', 'Not Me', 'Retired', 'Do Not Call', 'Meeting Booked'",
            }
          },
          required: ["meetingSet", "meetingInfo", "calleeEmail", "calleeName", "followUpCallSet", "followUpInfo"],
        },
      },
    },
  ];

  const fetchAudioAndTranscribe = async (audioUrl) => {
    setSummaryLoading(true);
    setAudioUrlHold(audioUrl);
    try {
      const response = await axios.get(audioUrl, { responseType: 'arraybuffer' });
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const formData = new FormData();
      formData.append('file', audioBlob, 'audio.mp3');
      formData.append('model', 'whisper-1');

      const transcriptResponse = await axios.post(
        'https://api.openai.com/v1/audio/transcriptions',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8`,
          },
        }
      );

      const transcriptText = transcriptResponse.data.text;
      setTranscript(transcriptResponse.data.text);

      const summaryResponse = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [{ role: "system", content: `Summarize the following call transcript's result in a list of bullet points separated with line breaks:\n\n${transcriptText}\n\nSummary:` }],
          max_tokens: 120,
        },
        {
          headers: { 'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8` },
        }
      );
      const pulledDataResponse = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [{ role: "system", content: `call end time (keep this in mind for meeting times, meeting times are set after this date, and WILL NOT BE SET IN THE PAST. TOMORROW WOULD BE THE DAY +1): ` + Date() + `, called number(if followup made but no number mentioned): (` + selectedRow.customerNumber + `) Pull important information from the call and return it using the informationPuller but only run it once to keep the information tidy:\n\n${transcriptText}\n\nSummary:` }],
          max_tokens: 120,
          tools: tools,
        },
        {
          headers: { 'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8` },
        });
      setSummary(summaryResponse.data.choices[0].message.content.trim());
      setSummaryLoading(false);
    } catch (error) {
      setSummaryLoading(false);
      console.error('Error fetching or transcribing audio:', error);
    }
  };

  const onRowClick = (record) => {
    setSelectedRowKey(record.key);
    setSelectedRow(record);
    setSummary('');
    setTranscript('');
  };

  const onRowManageClick = (record) => {
    setSelectedManageMatchbox(record);
    setSelectedManageRowKey(record.ListID);
  };

  const onRowClickAll = (record) => {
    setSelectedRowKeyAll(record.key);
    setSelectedRowAll(record);
    setSummary('');
    setTranscript('');
  };

  const rowClassName = (record) =>
    record.key === selectedRowKey ? 'selected' : '';

  const rowManageClassName = (record) =>
    record.ListID === selectedManageRowKey ? 'selected' : '';

  const rowClassNameAll = (record) =>
    record.key === selectedRowKeyAll ? 'selected' : '';


  function DataTable() {
    return (
      <Table
        dataSource={tableData}
        scroll={{ x: 400 }}
        pagination={{ pageSize: 8 }}
        size="small"
        columns={isCompact ? myColumnsCompact : myColumns}
        onChange={handleChange}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record);
          },
        })}
        rowClassName={rowClassName}
      />
    );
  }

  function DataTableAll() {
    return (
      <Table
        dataSource={allTableData}
        scroll={{ x: 400 }}
        size="small"
        columns={isCompact ? columnsCompact : columns}
        onChange={handleChange}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record);
          },
        })}
        rowClassName={rowClassName}
      />
    );
  }

  // Inside your component or function where you render the modal
  const availableComponentOptions = componentOptions.filter(option =>
    option.roles.includes(user.role)
  );

  const showAddComponentModal = () => {
    setIsAddComponentModalVisible(true);
  };

  const handleAddComponentOk = () => {
    addComponentForm
      .validateFields()
      .then((values) => {
        addComponentForm.resetFields();
        setIsAddComponentModalVisible(false);
        addComponent(values.component);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleAddComponentCancel = () => {
    setIsAddComponentModalVisible(false);
  };

  const handleUpload = ({ file, onSuccess, onError }) => {
    uploadForm
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        formData.append('file', file);
        if (values.listID) formData.append('listID', values.listID);

        axios
          .post('https://pbj.digital/create-list/', authToken, values)
          .then((response) => {
            const listID = response.data.listID;
            if (!values.listID) formData.append('listID', listID);
            axios
              .post('https://pbj.digital/upload-csv', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              })
              .then(() => {
                message.success(`${file.name} file uploaded successfully.`);
                onSuccess(null, file);
              })
              .catch((uploadError) => {
                message.error(`${file.name} file upload failed.`);
                onError(uploadError);
              });
          })
          .catch((listError) => {
            message.error('List creation failed.');
            onError(listError);
          });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
        onError(info);
      });
  };

  const showUploadModal = () => setIsUploadModalVisible(true);
  const handleUploadOk = () => {
    uploadForm.submit();
    setIsUploadModalVisible(false);
  };
  const handleUploadCancel = () => setIsUploadModalVisible(false);



  const expandedRowRender = (record) => {
    const itemColumns = [
      { title: 'First Name', dataIndex: 'first_name', key: 'fname' },
      { title: 'Last Name', dataIndex: 'last_name', key: 'lname' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      { title: 'Phone', dataIndex: 'kixie_phone_number', key: 'phone' },
      { title: 'Contact URL', dataIndex: 'contact_url', key: 'contacturl' },
    ];

    return <Table columns={itemColumns} size="small" dataSource={record.items} pagination={true} />;
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record?.id?.toString()]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  // Define grid items
  const [gridItems, setGridItems] = useState(() => {
    const savedItems = localStorage.getItem('gridItems');
    return savedItems
      ? JSON.parse(savedItems)
      : [
        { key: 'dialer', component: 'dialer' },
        { key: 'contactsBeingCalled', component: 'contactsBeingCalled' },
        { key: 'callerInfo', component: 'callerInfo' },
        // { key: 'callList', component: 'callList' },
        // { key: 'callTime', component: 'callTime' },
        // { key: 'summary', component: 'summary' },
        // { key: 'matchBoxes', component: 'matchBoxes' },
        // { key: 'assignLists', component: 'assignLists' },
      ];
  });

  // Save grid items to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('gridItems', JSON.stringify(gridItems));
  }, [gridItems]);

  // Save layouts to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('layouts', JSON.stringify(layouts));
  }, [layouts]);

  // Handler for layout change
  const onLayoutChange = (layout, allLayouts) => {
    setLayouts(allLayouts);
  };
  const listColumns = [
    { title: 'List ID', dataIndex: 'ListID', key: 'ListID' },
    { title: 'List Name', dataIndex: 'ListName', key: 'ListName' },
  ];


  // Handle dark mode toggle
  const toggleDarkMode = (checked) => {
    setIsDarkMode(checked);
  };

  const toggleCompact = (checked) => {
    setIsCompact(checked);
  };

  // Define a consistent color palette
  const colorPalette = {
    primary: '#00bcd4', // Cyan
    secondary: '#001020', // Dark Blue
    backgroundLight: '#f0f2f5',
    backgroundDark: '#001020',
    borderLight: '#d9d9d9',
    borderDark: '#434343',
    textLight: '#000000',
    textDark: '#ffffff',
  };

  // Use theme tokens for styling
  const { token } = antdTheme.useToken();

  // State for grid item style
  const [gridItemStyle, setGridItemStyle] = useState({
    backgroundColor: 'rgba(0,0,0,0)',
    border: `0px solid ${isDarkMode ? colorPalette.borderDark : colorPalette.borderLight
      }`,
    borderRadius: 0,
    padding: 0,
    overflow: 'auto',
    color: isDarkMode ? colorPalette.textDark : colorPalette.textLight,
  });
  const addComponent = (component) => {
    const componentOption = componentOptions.find(option => option.value === component);
    const newKey = `${component}-${Date.now()}`;
    const newItem = {
      key: newKey,
      component: component,
      roles: componentOption.roles,
      // Include any other necessary properties
    };
    setGridItems((prevItems) => [...prevItems, newItem]);

    // Update layouts
    setLayouts((prevLayouts) => {
      const newLayouts = { ...prevLayouts };
      const breakpoints = Object.keys(prevLayouts);
      breakpoints.forEach((breakpoint) => {
        const defaultW =
          breakpoint === 'lg'
            ? 4
            : breakpoint === 'md'
              ? 3
              : breakpoint === 'sm'
                ? 2
                : 1;
        newLayouts[breakpoint] = [
          ...newLayouts[breakpoint],
          { i: newKey, x: 0, y: Infinity, w: defaultW, h: 10 },
        ];
      });
      return newLayouts;
    });
  };




  // Update grid item style when theme changes
  useEffect(() => {
    setGridItemStyle({
      ackgroundColor: 'rgba(0,0,0,0)',
      border: `0px solid ${isDarkMode ? colorPalette.borderDark : colorPalette.borderLight
        }`,
      borderRadius: 0,
      padding: 0,
      overflow: 'auto',
      color: isDarkMode ? colorPalette.textDark : colorPalette.textLight,
    });
  }, [isDarkMode, token]);

  // Function to render grid items
  const renderGridItem = (item) => {
    // Function to handle delete
    const handleDeleteItem = (key) => {
      // Remove the item from gridItems
      setGridItems((prevItems) => prevItems.filter((i) => i.key !== key));
      // Remove the layout associated with the item
      setLayouts((prevLayouts) => {
        const newLayouts = {};
        Object.keys(prevLayouts).forEach((breakpoint) => {
          newLayouts[breakpoint] = prevLayouts[breakpoint].filter(
            (layout) => layout.i !== key
          );
        });
        return newLayouts;
      });
    };

    return (
      <>
        <Title level={4} className="grid-item-title">
          {/* Use item-specific titles */}
          {item.component === 'dialer' && 'Dialer'}
          {item.component === 'dataGatherer' && 'Data Gatherer'}
          {item.component === 'callList' && 'My Call History List'}
          {item.component === 'allCallList' && 'All Call History List'}
          {item.component === 'callTime' && 'Call Time'}
          {item.component === 'summary' && 'Summary'}
          {item.component === 'callerInfo' && 'Prospect Info'}
          {item.component === 'matchBoxes' && 'MatchBoxes'}
          {item.component === 'contactsBeingCalled' && 'Contacts Being Called'}
          {item.component === 'matchboxAssignment' && 'Assign MatchBoxes'}
          {item.component === 'website' && item.url}
        </Title>
        {/* Add delete button */}
        {/* Render the component content based on item.component */}
        {renderComponentContent(item)}
      </>
    );
  };

  const renderComponentContent = (item) => {
    if (!item?.roles?.includes(user.role)) {
      return <div>You do not have access to this component.</div>;
    }
    switch (item.component) {
      case 'dialer':
        return <PlivoWebphone answeredContact={setCallInfo} setShouldRefetch={setShouldRefetch} />;

      case 'dataGatherer':
        return <DataGatherer />;
      case 'callList':
        return (
          <>
            {DataTable()}
          </>
        );

      case 'allCallList':
        return (
          <>
            {DataTableAll()}
            <Button onClick={clearFilters}>Clear filters</Button>
            <Button onClick={clearAll}>Clear filters and sorters</Button>
          </>
        );

      case 'callTime':
        return selectedRow ? <CallTime callData={selectedRow} /> : null;

      case 'summary':
        return (
          <Space direction="vertical">
            <Text
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'left',
              }}
            >
              {summary}
            </Text>
            {summary === '' ? (
              <Button
                onClick={() =>
                  fetchAudioAndTranscribe(selectedRow.recordingurl)
                }
              >
                Transcribe and Summarize
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => navigateToUrl(selectedRow.recordingurl)}
              >
                Listen To Call
              </Button>
            )}
          </Space>
        );

      case 'callerInfo':
        return (
          <>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div className="row">
                <div class="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Prospect Name:</div>
                      <div className="statistic-value" id="answeredContactName">
                        (No prospect answered yet)
                      </div>
                    </Space>
                  </div>
                </div>

                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Title:</div>
                      <div className="statistic-value" id="answeredContactTitle">

                      </div>
                    </Space>
                  </div>
                </div>

                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Company:</div>
                      <div className="statistic-value" id="answeredContactCompany">

                      </div>
                    </Space>
                  </div>
                </div>
                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Number:</div>
                      <div className="statistic-value" id="answeredContactNumber">

                      </div>
                    </Space>
                  </div>
                </div>
                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Email:</div>
                      <div className="statistic-value" id="answeredContactEmail">

                      </div>
                    </Space>
                  </div>
                </div>
                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Custom 1:</div>
                      <div className="statistic-value" id="answeredContactCustom1">

                      </div>
                    </Space>
                  </div>
                </div>
                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Custom 2:</div>
                      <div className="statistic-value" id="answeredContactCustom2">

                      </div>
                    </Space>
                  </div>
                </div>
                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Custom 3:</div>
                      <div className="statistic-value" id="answeredContactCustom3">

                      </div>
                    </Space>
                  </div>
                </div>
                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Custom 4:</div>
                      <div className="statistic-value" id="answeredContactCustom4">

                      </div>
                    </Space>
                  </div>
                </div>
                <div className="col col-sm-12">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Custom 5:</div>
                      <div className="statistic-value" id="answeredContactCustom5">
                      </div>
                    </Space>
                  </div>
                </div>
                <div className="col col-sm-12" hidden="true">
                  <div className="statistic">
                    <Space direction="horizontal">
                      <div className="statistic-title">Id:</div>
                      <div className="statistic-value" id="answeredContactId">

                      </div>
                    </Space>
                  </div>
                </div>
              </div>
              <NotebookTextEditor isDarkMode={isDarkMode} answeredCaller={document.getElementById('answeredContactId')?.innerHTML} />
            </Space>
          </>
        );

      case 'matchBoxes':
        return (
          <>
            <CsvUploadModalComponent setMatchboxList={setLists} />
            <Table
              columns={listColumns}
              dataSource={lists}
              size="small"
              rowKey="ListID"
              expandable={{
                expandedRowRender,
                onExpand: handleExpand,
                expandedRowKeys,
                rowExpandable: (record) =>
                  record.items && record.items.length > 0,
              }}
              onRow={(record) => ({
                onClick: (clicked) => {
                  console.log('clicked', clicked);
                  setUnfilteredMatchbox(record);
                  setSelectedMatchbox(filterList(record));
                  setSelectedListId(record.ListID);
                  localStorage.setItem(
                    'selectedMatchbox',
                    JSON.stringify(filterList(record))
                  );
                },
              })}
              rowClassName={(record) =>
                record.ListID === selectedMatchbox?.ListID ? 'selected' : ''
              }
            />
          </>
        );

      case 'contactsBeingCalled':
        return selectedMatchbox ? (
          <Table
            size='small'
            dataSource={selectedMatchbox.items?.slice(0, 3) || []}
            columns={isCompact ? [{
              title: 'First Name',
              dataIndex: 'first_name',
              key: 'first_name',
            },
            {
              title: 'Last Name',
              dataIndex: 'last_name',
              key: 'last_name',
            },
            {
              title: 'Phone',
              dataIndex: 'kixie_phone_number',
              key: 'kixie_phone_number',
            }] : [
              {
                title: 'First Name',
                dataIndex: 'first_name',
                key: 'first_name',
              },
              {
                title: 'Last Name',
                dataIndex: 'last_name',
                key: 'last_name',
              },
              { title: 'Email', dataIndex: 'email', key: 'email' },
              {
                title: 'Phone',
                dataIndex: 'kixie_phone_number',
                key: 'kixie_phone_number',
              },
            ]}
            pagination={false}
            rowKey={(record) => record.id}
          />
        ) : (
          <p>Please select a MatchBox to view contacts.</p>
        );
      case 'matchboxAssignment':
        return <MatchboxAssignment />;
      case 'website':
        return (
          <iframe
            src={item.url}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title={item.url}
          />
        );

      default:
        return null;
    }
  };

  // Function to add a new website card
  const addWebsite = (url) => {
    const newKey = `website-${Date.now()}`;
    const newItem = {
      key: newKey,
      component: 'website',
      url: url,
    };
    setGridItems((prevItems) => [...prevItems, newItem]);

    // Update layouts
    setLayouts((prevLayouts) => {
      const newLayouts = { ...prevLayouts };
      const breakpoints = Object.keys(prevLayouts);
      breakpoints.forEach((breakpoint) => {
        const defaultW =
          breakpoint === 'lg'
            ? 6
            : breakpoint === 'md'
              ? 5
              : breakpoint === 'sm'
                ? 3
                : 2;
        newLayouts[breakpoint] = [
          ...newLayouts[breakpoint],
          { i: newKey, x: 0, y: Infinity, w: defaultW, h: 10 },
        ];
      });
      return newLayouts;
    });
  };

  // Modal handling functions for adding a website
  const showAddWebsiteModal = () => {
    setIsAddWebsiteModalVisible(true);
  };

  const handleAddWebsiteOk = () => {
    addWebsiteForm
      .validateFields()
      .then((values) => {
        addWebsiteForm.resetFields();
        setIsAddWebsiteModalVisible(false);
        addWebsite(values.url);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleAddWebsiteCancel = () => {
    setIsAddWebsiteModalVisible(false);
  };

  const items = [
    {
      key: '1',
      label: 'Select List',
      children: <>
        <Row gutter={16}>
          <Col span={12}>
            <Table
              columns={listColumns}
              dataSource={lists}
              size="small"
              rowKey="ListID"
              // expandable={{
              //   expandedRowRender,
              //   onExpand: handleExpand,
              //   expandedRowKeys,
              //   rowExpandable: (record) =>
              //     record.items && record.items.length > 0,
              // }}
              onRow={(record) => ({
                onClick: () => {
                  localStorage.setItem(
                    'selectedMatchbox',
                    JSON.stringify(filterList(record))
                  );
                  setSelectedMatchbox(filterList(record));
                  setUnfilteredMatchbox(record);
                  if (user.role != 'DataManager') {
                    setSelectedTab('2');
                  }
                  setSelectedListId(record.ListID);
                },
              })}
              rowClassName={(record) =>
                record.ListID === selectedMatchbox?.ListID ? 'selected' : ''
              }
            />
          </Col>
          <Col span={12}>
          <Button onClick={()=> setShouldRefetch(!shouldRefetch)}><RedoOutlined/></Button>
          </Col>
        </Row>
      </>,
      icon: <DatabaseOutlined />,
    },
    {
      key: '2',
      label: 'Dialer',
      disabled: user.role == 'DataManager',
      children: <>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <PlivoWebphone answeredContact={setCallInfo} setShouldRefetch={setShouldRefetch} setLists={setLists} selectedListId={selectedListId} setUnfilteredMatchbox={setUnfilteredMatchbox} filterList={filterList} setSelectedMatchbox={setSelectedMatchbox} pushNumbersToEndpoint={pushNumbersToEndpoint}/>
              </Col>
              <Col span={24}>
                <div className="row">
                  <div class="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Prospect Name:</div>
                        <div className="statistic-value" id="answeredContactName">
                          (No prospect answered yet)
                        </div>
                      </Space>
                    </div>
                  </div>

                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Title:</div>
                        <div className="statistic-value" id="answeredContactTitle">

                        </div>
                      </Space>
                    </div>
                  </div>

                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Company:</div>
                        <div className="statistic-value" id="answeredContactCompany">

                        </div>
                      </Space>
                    </div>
                  </div>
                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Number:</div>
                        <div className="statistic-value" id="answeredContactNumber">

                        </div>
                      </Space>
                    </div>
                  </div>
                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Email:</div>
                        <div className="statistic-value" id="answeredContactEmail">

                        </div>
                      </Space>
                    </div>
                  </div>
                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Custom 1:</div>
                        <div className="statistic-value" id="answeredContactCustom1">

                        </div>
                      </Space>
                    </div>
                  </div>
                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Custom 2:</div>
                        <div className="statistic-value" id="answeredContactCustom2">

                        </div>
                      </Space>
                    </div>
                  </div>
                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Custom 3:</div>
                        <div className="statistic-value" id="answeredContactCustom3">

                        </div>
                      </Space>
                    </div>
                  </div>
                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Custom 4:</div>
                        <div className="statistic-value" id="answeredContactCustom4">

                        </div>
                      </Space>
                    </div>
                  </div>
                  <div className="col col-sm-12">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Custom 5:</div>
                        <div className="statistic-value" id="answeredContactCustom5">
                        </div>
                      </Space>
                    </div>
                  </div>
                  <div className="col col-sm-12" hidden="true">
                    <div className="statistic">
                      <Space direction="horizontal">
                        <div className="statistic-title">Id:</div>
                        <div className="statistic-value" id="answeredContactId">

                        </div>
                      </Space>
                    </div>
                  </div>
                </div>


              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <>
              <Space direction="vertical" style={{ width: '100%' }}>
                
                {selectedMatchbox ? (
                  <>
                    {selectedMatchbox.items?.slice(0, 3).length < 3 ? <Tag color="red">End of Matchbox</Tag>: <></>}
                 
                  <Table
                    size='small'
                    dataSource={selectedMatchbox.items?.slice(0, 3) || []}
                    columns={isCompact ? [{
                      title: 'First Name',
                      dataIndex: 'first_name',
                      key: 'first_name',
                    },
                    {
                      title: 'Last Name',
                      dataIndex: 'last_name',
                      key: 'last_name',
                    },
                    {
                      title: 'Phone',
                      dataIndex: 'kixie_phone_number',
                      key: 'kixie_phone_number',
                    }] : [
                      {
                        title: 'First Name',
                        dataIndex: 'first_name',
                        key: 'first_name',
                      },
                      {
                        title: 'Last Name',
                        dataIndex: 'last_name',
                        key: 'last_name',
                      },
                      {
                        title: 'Title',
                        dataIndex: 'title',
                        key: 'title',
                      },
                      { title: 'Email', dataIndex: 'email', key: 'email' },
                      {
                        title: 'Phone',
                        dataIndex: 'kixie_phone_number',
                        key: 'kixie_phone_number',
                      }
                    ]}
                    pagination={false}
                    rowKey={(record) => record.id}
                  />
                  </>
                ) : (
                  <p>Please select a MatchBox to view contacts.</p>
                )}
                <Space direction="horizontal">
                <div className="row">
                {/* <p>AI Picked:</p>
                <div id="dispositionFromAI" hidden={false}></div> */}
                <p>Select for Disposition</p>
                <Select
                  id="dispositionDropdown"
                  placeholder="Select a disposition"
                  onChange={(value) => { handleDispositionChangeMainPage(value, document.getElementById('answeredContactId').innerHTML); console.log(document.getElementById('answeredContactId').innerHTML); }}
                  value={dispo}
                  style={{ width: 175 }}
                >
                  <Option value="Not interested">Not interested</Option>
                  <Option value="Bad Data">Bad Data</Option>
                  <Option value="Send Information">Send Information</Option>
                  <Option value="Left Company">Left Company</Option>
                  <Option value="Follow Up">Follow Up</Option>
                  <Option value="Hung Up">Hung Up</Option>
                  <Option value="Not Me">Not Me</Option>
                  <Option value="Retired">Retired</Option>
                  <Option value="Do Not Call">Do Not Call</Option>
                  <Option value="Meeting Booked">Meeting Booked</Option>
                  <Option value="Call Back Later">Call Back Later</Option>
                  <Option value="no answer">No Answer</Option>
                </Select>
                </div>
                <NotebookTextEditor isDarkMode={isDarkMode} answeredCaller={document.getElementById('answeredContactId')?.innerHTML} />
                </Space>
                {/* <div id="AILoading" hidden={true}>
                  <Card>
                    <Space direction="horizontal" align="center">
                      <Spin size="large" tip="Transcribing..." />
                      <Title level={4}>AI Transcribing...</Title>
                    </Space>
                  </Card>
                </div> */}
                <Button
                  type="primary"
                  id="urlButton"
                  onClick={() => {
                    navigator.clipboard.writeText(document.getElementById('urlButton').innerHTML);
                    
                    message.success('Audio URL copied to clipboard');
                  }}
                >
                  
                </Button>
                {/* <Card title="Summary" visible={localStorage.getItem('answeredNumber') ? true : false}>
                  <div id="Summary" hidden={true} style={{ whiteSpace: "pre-wrap" }}>
                  </div>
                </Card> */}
              </Space>
            </>
          </Col>
        </Row>
      </>,
      icon: <PhoneOutlined />,
    },
    // {
    //   key: '3',
    //   label: 'Call Review',
    //   children: <>
    //     <CallDataTable
    //       isCompact={isCompact}
    //       onRowClick={onRowClick}
    //       rowClassName={rowClassName}
    //       selectedRowKey={selectedRowKey}
    //       user={{ fname: user.username }}
    //       setSelectedRow={setSelectedRow}
    //     />
    //     <Card style={{ whiteSpace: "pre-wrap" }}>{selectedRow?.data?.choices[0].message.content}</Card>
    //   </>,
    //   icon: <ExperimentOutlined />,
    // },
    {
      key: '4',
      label: 'Report',
      children: (
        <Spin spinning={loading}>
          <InternalDash/>
        </Spin>
      ),
      icon: <DatabaseOutlined />,
    },
    {
      key: '5',
      label: 'Client Dashboard',
      children: (
        <ClientDash/>
      ),
    }
  ];


  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorPalette.primary,
          colorText: isDarkMode ? colorPalette.textDark : colorPalette.textLight,
          colorBgBase: isDarkMode
            ? colorPalette.backgroundDark
            : colorPalette.backgroundLight,
          colorBgContainer: isDarkMode ? '#001529' : '#ffffff',
          colorBorder: isDarkMode
            ? colorPalette.borderDark
            : colorPalette.borderLight,
          borderRadius: 8,
          fontSize: 16,
          lineHeight: 1.5,
        },
        components: {
          Button: {
            borderRadius: 4,
          },
          Input: {
            borderRadius: 4,
          },
          Table: {
            cellPaddingInlineSM: 4,
            cellPaddingBlockSM: 2,
            headerBg: isDarkMode
              ? colorPalette.backgroundDark
              : colorPalette.backgroundLight,
          },
          Tabs: {
            verticalItemPadding: "8px 0px",
          }
        },
        algorithm: isDarkMode
          ? antdTheme.darkAlgorithm
          : antdTheme.defaultAlgorithm,
      }}
    >
      <Layout style={{ minHeight: '100vh' }}>
        {/* <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          theme={isDarkMode ? 'dark' : 'light'}
        >
          <div className="logo" />
          <Menu
            theme={isDarkMode ? 'dark' : 'light'}
            defaultSelectedKeys={['2']}
            mode="inline"
            items={[
              { key: '1', label: 'Create Client' },
              { key: '2', label: 'Calls' },
              { key: '3', label: 'Reports' },
              { key: '4', label: 'Settings' },
            ]}
          />
        </Sider> */}
        <Layout className="site-layout">
          <Header
            style={{
              background: 'none',
              padding: '0 16px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Logo />
            <Space style={{ marginLeft: 'auto' }}>
              <Button icon={<SettingOutlined />} onClick={() => { setSettingsOpen(true) }}> Admin </Button>
              <Text>{isDarkMode ? 'Dark Mode' : 'Light Mode'}</Text>
              <Switch checked={isDarkMode} onChange={toggleDarkMode} />
              <Text>Compact</Text>
              <Switch checked={isCompact} onChange={toggleCompact} />
              {/* Add the "+" button */}
              {/* <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={showAddComponentModal}
              /> */}
            </Space>
          </Header>
          <Content style={{ margin: '16px', marginTop: 0 }}>
            <Modal
              title="Add Component"
              visible={isAddComponentModalVisible}
              onOk={handleAddComponentOk}
              onCancel={handleAddComponentCancel}
            >
              <Form form={addComponentForm} layout="vertical">
                <Form.Item
                  name="component"
                  label="Select Component"
                  rules={[{ required: true, message: 'Please select a component!' }]}
                >
                  <Select options={availableComponentOptions} />
                </Form.Item>
              </Form>
            </Modal>
            <Modal title="Admin" open={settingsOpen} onOk={() => { setSettingsOpen(false) }} width="70%" onCancel={() => { setSettingsOpen(false) }} footer={[]}>

              {/* <Card><CallHistorySearch /></Card> */}
              <Tabs defaultActiveKey="1" type="card" tabPosition='left' style={{ border: '1px' }} items={[{
                label: `Caller Management`,
                key: 2,
                children: <Card title="Caller Management" style={{ padding: 0, border: "0px" }} bordered='false'></Card>,
                disabled: true,
              }, {
                label: `Matchbox Management`,
                key: 3,
                children: <Card title="Matchbox Management" style={{ padding: 0, border: "0px" }} bordered='false'><MatchboxAssignment /> <CsvUploadModalComponent setMatchboxList={setLists} /></Card>,
              },{
                label: `Client Management`,
                key: 4,
                children: <Card title="Client Management" style={{ padding: 0, border: "0px" }} bordered='false'><ClientManagement/></Card>,
              }]} />

            </Modal>
            <Tabs defaultActiveKey="1" items={items} type="card" activeKey={selectedTab} onTabClick={(tab) => { setSelectedTab(tab) }} />
            {/* <ResponsiveGridLayout
              className="layout"
              layouts={layouts}
              onLayoutChange={onLayoutChange}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 2 }}
              rowHeight={30}
            >
              {gridItems.map((item) => (
                <div key={item.key} style={gridItemStyle}>
                  {renderGridItem(item)}
                </div>
              ))}
            </ResponsiveGridLayout> */}
          </Content>
          {/* Modals */}
          <Modal
            title="Add New Association"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form} layout="vertical">
              <Form.Item
                name="businessid"
                label="Business ID"
                rules={[{ required: true, message: 'Please input the business ID!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="company_name"
                label="Company Name"
                rules={[{ required: true, message: 'Please input the company name!' }]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
          {/* Add Website Modal */}
          <Modal
            title="Add Website"
            visible={isAddWebsiteModalVisible}
            onOk={handleAddWebsiteOk}
            onCancel={handleAddWebsiteCancel}
          >
            <Form form={addWebsiteForm} layout="vertical">
              <Form.Item
                name="url"
                label="Website URL"
                rules={[
                  { required: true, message: 'Please input the website URL!' },
                ]}
              >
                <Input placeholder="https://example.com" />
              </Form.Item>
            </Form>
          </Modal>
          <Footer color={isDarkMode ? '#001529' : '#ffffff'} fontColor={isDarkMode ? colorPalette.textDark : colorPalette.textLight} />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

export default App;