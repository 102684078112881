import React, { useState } from 'react';
import { Modal, Form, Select, message } from 'antd';
import axios from 'axios';

const AssignListToClientModal = ({
  isAssignListModalVisible,
  setIsAssignListModalVisible,
  fetchMatchboxes,
  matchboxes,
  clientIds,
  authToken,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false); // To handle loading state

  // Function to assign matchbox to a single client
  const assignMatchbox = async (values) => {
    const { matchboxId, clientId } = values; // Use a single clientId

    try {
      setIsSubmitting(true);

      // Make a single POST request
      await axios.post(
        'https://pbj.digital/assign-lists-to-client',
        { matchboxId, clientId, action: 'assign' },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      message.success('Matchbox assigned successfully to the selected client.');
      fetchMatchboxes(); // Refresh the list
    } catch (error) {
      console.error('Error assigning matchbox:', error);
      message.error(
        error.response?.data?.error || 'An error occurred while assigning the matchbox.'
      );
    } finally {
      setIsSubmitting(false);
      form.resetFields();
      setIsAssignListModalVisible(false);
    }
  };

  const handleAssignOk = () => {
    form
      .validateFields()
      .then((values) => {
        assignMatchbox(values);
      })
      .catch((info) => {
        console.log('Validation Failed:', info);
      });
  };

  const handleAssignCancel = () => {
    form.resetFields();
    setIsAssignListModalVisible(false);
  };

  return (
    <Modal
      title="Assign Matchbox"
      visible={isAssignListModalVisible}
      onOk={handleAssignOk}
      onCancel={handleAssignCancel}
      confirmLoading={isSubmitting}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="matchboxId"
          label="Select Matchbox"
          rules={[{ required: true, message: 'Please select a matchbox!' }]}
        >
          <Select placeholder="Select a matchbox">
            {matchboxes.map((mb) => (
              <Select.Option key={mb.ListID} value={mb.ListID}>
                {mb.ListName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="clientId"
          label="Select Client"
          rules={[{ required: true, message: 'Please select a client!' }]}
        >
          <Select
            placeholder="Select a client"
            allowClear
          >
            {clientIds.map((client) => (
              <Select.Option key={client.id} value={client.id}>
                {client.companyName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignListToClientModal;