import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, message } from 'antd';
import axios from 'axios';

const AssignMatchboxModal = ({
    isAssignModalVisible,
    setIsAssignModalVisible,
    fetchMatchboxes,
    matchboxes,
    callers,
    authToken,
  }) => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false); // To handle loading state
  
    // Function to assign matchbox to multiple callers
    const assignMatchbox = async (values) => {
      const { matchboxId, callerIds } = values; // callerIds will be an array
  
      try {
        setIsSubmitting(true);
        // Create an array of POST requests for each callerId
        const assignRequests = callerIds.map((callerId) =>
          axios.post(
            'https://pbj.digital/assign-matchbox',
            { matchboxId, callerId, action: 'assign' }, // Include action
            {
              headers: { Authorization: `Bearer ${authToken}` },
            }
          )
        );
        
        // Execute all assignment requests in parallel
        const responses = await Promise.all(assignRequests);
  
        // Check for any failed assignments
        const failedAssignments = responses.filter(
          (response) => response.status !== 200
        );
  
        if (failedAssignments.length === 0) {
          message.success('Matchbox assigned successfully to all selected callers.');
        } else {
          message.warning(
            `${failedAssignments.length} out of ${callerIds.length} assignments failed.`
          );
        }
  
        fetchMatchboxes(); // Refresh the list
      } catch (error) {
        console.error('Error assigning matchbox:', error);
        message.error(
          error.response?.data?.error || 'Error assigning matchbox.'
        );
      } finally {
        setIsSubmitting(false);
        form.resetFields();
        setIsAssignModalVisible(false);
      }
    };
  
    const handleAssignOk = () => {
      form
        .validateFields()
        .then((values) => {
          assignMatchbox(values);
        })
        .catch((info) => {
          console.log('Validate Failed:', info);
        });
    };
  
    const handleAssignCancel = () => {
      form.resetFields();
      setIsAssignModalVisible(false);
    };
  
    return (
      <Modal
        title="Assign Matchbox"
        visible={isAssignModalVisible}
        onOk={handleAssignOk}
        onCancel={handleAssignCancel}
        confirmLoading={isSubmitting} // Show loading state on the OK button
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="matchboxId"
            label="Select Matchbox"
            rules={[{ required: true, message: 'Please select a matchbox!' }]}
          >
            <Select placeholder="Select a matchbox">
              {matchboxes.map((mb) => (
                <Select.Option key={mb.ListID} value={mb.ListID}>
                  {mb.ListName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="callerIds"
            label="Select Callers"
            rules={[{ required: true, message: 'Please select at least one caller!' }]}
          >
            <Select
              mode="multiple" // Enable multiple selection
              placeholder="Select one or more callers"
              allowClear
            >
              {callers.map((caller) => (
                <Select.Option key={caller.id} value={caller.id}>
                  {caller.username} {/* or caller.name if available */}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  
  export default AssignMatchboxModal;